/* eslint-disable @typescript-eslint/class-name-casing */
/* eslint-disable @typescript-eslint/camelcase */
import PROJECTS from "@/assets/json/projects.json";

import {
  ColDef,
  ColGroupDef,
  ValueFormatterParams,
  ValueGetterParams,
  GridOptions,
  GridApi,
  ColumnApi,
  RowDragLeaveEvent,
  RowDragEndEvent,
  RowNode,
} from "ag-grid-community";
import { computed, onMounted, onUpdated, reactive, ref } from "vue";

import AgGridSwitch from "@/components/widgets/tables/aggridfullpage/custom-components/AgGridSwitch.vue";
import AgGridDatePicker from "@/components/widgets/tables/aggridfullpage/custom-components/AgGridDatepicker.vue";
import CustomGroupHeader from "./CustomGroupHeader.vue";

import { useStore } from "vuex";
import { useDebounceFn } from "@vueuse/core";

import { Actions } from "@/store/enums/StoreEnums";
import { Actions as AggridActions } from "@/store/enums/AggridEnums";
import { Actions as ProjectActions } from "@/store/enums/ProjectEnums";
import { Project } from "@/core/enums/ProjectEnums";
import { saveState } from "@/core/helpers/aggrid";
import { translate, formatNumbers } from "@/core/helpers/config";

enum State {
  tableName = "table_name",
  userId = "user_id",
}

export const ExampleSetup = () => {
  const store = useStore();
  const aggridData = ref<any[]>([]);
  const aggridDataCopy = ref<any[]>([]);
  const enableGroupMode = ref(false);
  const gridApi = ref<GridApi | undefined>();
  const colApi = ref<ColumnApi | undefined>();
  const potentialParent = ref();
  const queue = ref<ColDef[]>([]);
  const getSelectedNodes = ref<RowNode[] | undefined>([]);
  const queueModified = computed(() => {
    return queue.value.map((project) => {
      delete project[Project.createdAt];
      delete project[Project.updatedAt];
      return project;
    });
  });
  const columnState = computed(() => {
    return store.getters.getStates.filter(
      (state) => state[State.tableName] === "projects"
    );
  });

  const frameworkComponents = reactive({
    AgGridSwitch,
    AgGridDatePicker,
  });

  const moveMultiToPath = (newParentPath, node, allUpdatedNodes) => {
    const oldPath = node.data.name.split("/");
    const fileName = oldPath[oldPath.length - 1];

    const newChildPath = newParentPath.split("/");
    newChildPath.push(fileName);
    node.data.name = newChildPath.join("/");
    allUpdatedNodes.push(node.data);
  };

  const moveToPath = (newParentPath, node, allUpdatedNodes) => {
    console.log(newParentPath);
    const oldPath = node.data.name.split("/");
    const fileName = oldPath[oldPath.length - 1];
    let newChildPath;

    if (typeof newParentPath === "string") {
      newChildPath = [newParentPath];
    } else {
      newChildPath = newParentPath;
    }

    newChildPath.push(fileName);
    node.data.name = newChildPath.join("/");
    allUpdatedNodes.push(node.data);

    if (node.childrenAfterGroup) {
      node.childrenAfterGroup.forEach(function(childNode) {
        moveToPath(newChildPath, childNode, allUpdatedNodes);
      });
    }
  };

  const moveInArray = (arr, fromIndex, toIndex) => {
    const element = arr[fromIndex];
    arr.splice(fromIndex, 1);
    arr.splice(toIndex, 0, element);
  };

  const isSelectionParentOfTarget = (selectedNode, targetNode) => {
    const children = selectedNode.childrenAfterGroup;
    if (!children) return;
    for (let i = 0; i < children.length; i++) {
      if (targetNode && children[i].key === targetNode.key) return true;
      isSelectionParentOfTarget(children[i], targetNode);
    }
    return false;
  };

  const arePathsEqual = (path1, path2) => {
    if (typeof path1 === "string" || typeof path2 === "string") return false;
    if (path1.length !== path2.length) return false;

    let equal = true;
    path1.forEach(function(item, index) {
      if (path2[index] !== item) {
        equal = false;
      }
    });
    return equal;
  };

  const refreshRows = (api, rowsToRefresh) => {
    const params = {
      rowNodes: rowsToRefresh,
      force: true,
    };
    api.refreshCells(params);
  };

  const setPotentialParentForNode = (api, overNode) => {
    let newPotentialParent;
    if (overNode) {
      newPotentialParent =
        overNode.data.type === "parent" ? overNode : overNode.parent;
    } else {
      newPotentialParent = null;
    }
    const alreadySelected = potentialParent.value === newPotentialParent;
    if (alreadySelected) {
      return;
    }
    const rowsToRefresh = [] as any[];
    if (potentialParent.value) {
      rowsToRefresh.push(potentialParent.value);
    }
    if (newPotentialParent) {
      rowsToRefresh.push(newPotentialParent);
    }
    potentialParent.value = newPotentialParent;
    refreshRows(api, rowsToRefresh);
  };

  const unGroupRowsByCheckbox = () => {
    const selectedNodes = getSelectedNodes.value as RowNode[];

    selectedNodes!
      .filter((node) => node.isSelected)
      .forEach((node) => {
        console.log(node);

        const treeStructure = node.data.name.split("/");
        const updatedRows = [] as RowNode[];
        const newParentPath = treeStructure.pop();

        // Set all selected row as child node of new parent node when removing row from grouping
        node["data"]["type"] = "child";
        node.data.name = newParentPath;

        updatedRows.push(node.data);

        gridApi.value!.applyTransaction({
          update: updatedRows,
          addIndex: node.rowIndex,
        });

        console.log(updatedRows);
        node.setSelected(false);
        gridApi.value!.clearFocusedCell();
        gridApi.value!.redrawRows();
      });
  };

  const moveRowsUpLevel = () => {
    const selectedNodes = getSelectedNodes.value as RowNode[];

    selectedNodes!
      .filter((node) => node.isSelected)
      .forEach((node) => {
        const treeStructure = node.data.name.split("/");
        // 3+ level below parent therefore move row up a level
        if (treeStructure.length > 2) {
          console.log("Node nested more than 2 levels, moving up 1 level");
          // Set all selected row as child node of new parent node when moving row up a level
          node["data"]["type"] = "child";
          const updatedRows = [];
          const newParentPath = treeStructure
            .reverse()
            .slice(treeStructure.length - (treeStructure.length - 2))
            .reverse()
            .join("/");

          moveToPath(newParentPath, node, updatedRows);
          gridApi.value!.applyTransaction({
            update: updatedRows,
          });
          node.setSelected(false);
          gridApi.value!.clearFocusedCell();
          gridApi.value!.redrawRows();
        }
        console.log("Node nested 1 level under parent, removing from group");
        unGroupRowsByCheckbox();
      });
  };

  const groupRowsByCheckbox = () => {
    // Remove first select row from array and set it as parent node
    const overNode = getSelectedNodes.value!.shift() as any;
    overNode!["data"]["type"] = "parent";
    getSelectedNodes.value!.forEach((node) => {
      node["data"]["type"] = "child";
    });

    // Pretty much a ternary operator that checks if first selected node is a parent
    // if node is parent then use current node if not use parent node of current node
    const folderToDropInto =
      overNode!["data"]["type"] === "parent" ? overNode! : overNode!["parent"];

    // Gets name from parent node
    const newParentPath = folderToDropInto!.data
      ? folderToDropInto!.data.name
      : [];

    // Get key values from leaf children in parent node
    const nodesToDeleteArr = overNode?.allLeafChildren.map((node) => node.key);

    // Filter out selectedNodes with leaf children
    const newArr = getSelectedNodes.value!.filter(
      (node) => !nodesToDeleteArr?.includes(node.key)
    );

    newArr.forEach((node) => {
      const needToChangeParent = !arePathsEqual(newParentPath, node.data.name);
      const invalidMode = isSelectionParentOfTarget(node, folderToDropInto);
      if (invalidMode) {
        console.log("invalid move");
      }

      if (needToChangeParent && !invalidMode) {
        const updatedRows = [];

        moveMultiToPath(newParentPath, node, updatedRows);

        gridApi.value!.applyTransaction({
          update: updatedRows,
        });
        gridApi.value!.clearFocusedCell();
      }
    });
    gridApi.value!.redrawRows();
    gridApi.value!.deselectAll();
  };

  const setNodeCheckboxIndetermine = (node) => {
    const el = document.querySelectorAll(`[row-id="${node.id}"]`)[1];
    const checkboxWrapper = el.querySelectorAll(":scope .ag-wrapper")[0];

    checkboxWrapper?.classList.add("ag-indeterminate");
  };

  const removeNodeCheckboxIndetermine = (node) => {
    const el = document.querySelectorAll(`[row-id="${node.id}"]`)[1];
    const checkboxWrapper = el.querySelectorAll(":scope .ag-wrapper")[0];

    checkboxWrapper?.classList.remove("ag-indeterminate");
  };

  const getRowNodesRecursive = (selectedRowNode, rowNodeArray) => {
    for (const [key, value] of Object.entries(selectedRowNode) as any) {
      if (key === "parent") {
        if (value.key) {
          rowNodeArray.push(value);
          getRowNodesRecursive(value, rowNodeArray);
        }
        return rowNodeArray;
      }
    }
  };

  const handleGroupTotal = (
    propName: string,
    leafNodes: RowNode[] | undefined
  ): number => {
    // Create new array of values from propName
    const valuesArray = leafNodes!.map((row) => +row["data"][propName]);
    // return sum of all values in valuesArray
    const total = valuesArray.reduce(function(a, b) {
      return a + b;
    }, 0);
    return +total.toFixed(2);
  };

  const handleGroupTotalTooltip = (
    propName: string,
    leafNodes: RowNode[] | undefined
  ): string => {
    const valuesArray = leafNodes!.map((row) => row["data"][propName]);
    // valuesArray.shift();
    return valuesArray.join(" + ");
  };

  const threeFifthsIndetermine = (rowNodeArray, selectedNodeBoolCount) => {
    // console.log(rowNodeArray);

    rowNodeArray.forEach((node: any) => {
      // Mixture of checked and unchecked nodes
      if (selectedNodeBoolCount?.every((v) => v === selectedNodeBoolCount[0])) {
        setNodeCheckboxIndetermine(node);
      }

      // All nodes under parent are checked
      if (selectedNodeBoolCount?.every(Boolean)) {
        removeNodeCheckboxIndetermine(node);
      }

      // All nodes under parent are not checked
      if (selectedNodeBoolCount?.every((v) => v === false)) {
        console.log(node);
        node.setSelected(false);

        // setNodeCheckboxIndetermine(node);

        removeNodeCheckboxIndetermine(node);
      }
    });
  };

  const recursiveTest = (leafNodeArray) => {
    console.log(leafNodeArray);
  };

  const groupNameArray = computed(() =>
    gridApi.value?.getRenderedNodes().map((node) => node.key)
  );

  const bodyOptionsTop = ref<GridOptions | ColGroupDef>({
    editType: "fullRow",
    rowSelection: "multiple",
    enableRangeSelection: true,
    enableFillHandle: true,
    fillHandleDirection: "y",
    debounceVerticalScrollbar: true,
    suppressMoveWhenRowDragging: true,
    rowDragManaged: false,
    rowModelType: "clientSide",
    treeData: true,
    rowDragEntireRow: false,
    immutableData: true,
    rowDragMultiRow: true,
    suppressRowClickSelection: true,
    autoGroupColumnDef: {
      colId: "ag-Grid-AutoColumn",
      rowDrag: true,
      editable: true,
      minWidth: 350,
      maxWidth: 350,
      field: "name",
      headerName: "Project Name",
      // onCellValueChanged: (params) => {
      //   // console.log(params);
      //   if (!params) return;
      //   if (!params.newValue) return;
      //   // console.log(gridApi.value?.getRenderedNodes());
      //   if (
      //     gridApi.value
      //       ?.getRenderedNodes()
      //       .map((node) => node.key)
      //       ?.includes(params.newValue)
      //   ) {
      //     store.dispatch(Actions.SET_TOASTER_ACTION, {
      //       type: "error",
      //       title: "This row name is already being used!",
      //       allowUndo: false,
      //       duration: 5000,
      //     });
      //     return;
      //   }
      //   params.node?.setDataValue("name", params.newValue);
      //   params.node!.key = params.newValue;
      // },
      valueFormatter: (params) => {
        if (!params.data || !params.data.name) {
          return null;
        }
        const array = params.data.name.split("/");
        const name = array[array.length - 1];

        return name.toString();
      },
      tooltipValueGetter: (params) => {
        if (
          gridApi.value
            ?.getRenderedNodes()
            .map((node) => node["data"]["name"])
            .filter((node) => node === params.value).length! > 1
        ) {
          return "Group name should be unique.";
        }
        return "";
      },
      cellClass: (params) => {
        if (
          gridApi.value
            ?.getRenderedNodes()
            .map((node) => node["data"]["name"])
            .filter((node) => node === params.value).length! > 1
        ) {
          return "bg-danger text-white";
        }
        return "text-black";
      },
      suppressMenu: true,
      cellRenderer: function(params) {
        if (
          gridApi.value
            ?.getRenderedNodes()
            .map((node) => node["data"]["name"])
            .filter((node) => node === params.value).length! > 1
        ) {
          return (
            "<span>" +
            params.value +
            " <i class='fas fa-exclamation-circle text-white'></i></span>"
          );
        }

        return "<span>" + params.value + "</span>";
      },
      checkboxSelection: true,
      // cellRendererParams: { checkbox: true },
    },
    getRowClass: (params) => {
      // console.log(params);
      if (!params.node.allChildrenCount) return "";
      if (params.node.allChildrenCount) {
        return ["bg-info", "bg-opacity-25", "text-gray-700", "fw-bold", "fw-6"];
      }
    },
    getRowNodeId: (data) => {
      return data.doc_no;
    },
    getDataPath: (data) => {
      if (typeof data.name == "string") {
        return data.name.split("/");
      }
      return data.name;
    },
    onRowSelected: (params) => {
      // console.log(params);
      getSelectedNodes.value = gridApi.value?.getSelectedNodes();
      const nodeIsParent =
        params.node.allChildrenCount !== null &&
        params.node.allChildrenCount >= 1;
      // console.log(nodeIsParent);

      const rowNodeArray = [];
      getRowNodesRecursive(params.node, rowNodeArray);

      // Check if all children local to selected node is selected
      // const selectedNodeCount = params.node.parent?.childrenAfterGroup?.map(
      //   (node) => node.isSelected()
      // );

      // console.log(selectedNodeCount);

      if (!params.node.isSelected()) {
        // console.log(params.node);
        // threeFifthsIndetermine(rowNodeArray, selectedNodeCount);

        // Check if node is parent
        if (!nodeIsParent) {
          const el = document.querySelectorAll(
            `[row-index="${params.rowIndex}"]`
          )[1];
          el?.classList.remove(
            "bg-info",
            "bg-opacity-25",
            "text-gray-700",
            "fw-bold",
            "fw-6"
          );

          rowNodeArray.forEach((node: any) => {
            if (node.allChildrenCount < 2) {
              node.setSelected(false);
            }
            // node.setSelected(undefined);

            // const allLeafChildren = node.allLeafChildren;
            // let parentNode;

            console.log(node);
          });
        }

        // Uncheck all child nodes if parent node is unchecked
        params.node.childrenAfterGroup!.forEach((childNode) => {
          childNode.setSelected(false);
        });
        return;
      }

      const el = document.querySelectorAll(
        `[row-index="${getSelectedNodes.value![0].rowIndex}"]`
      )[1];

      el?.classList.add(
        "bg-info",
        "bg-opacity-25",
        "text-gray-700",
        "fw-bold",
        "fw-6"
      );

      if (!nodeIsParent) {
        rowNodeArray.forEach((node: any) => {
          if (node.allChildrenCount < 2) {
            node.setSelected(true);
            return;
          }
          const allLeafChildren = node.allLeafChildren;
          // console.log(allLeafChildren);
          // const allLeafChildrenClone = [...allLeafChildren];
          // allLeafChildrenClone.shift();
          // console.log(allLeafChildrenClone);
          // let parentNode;

          // if (!Object.isFrozen(allLeafChildren)) {
          //   parentNode = allLeafChildren.shift();
          // }

          const leafChildren = allLeafChildren;

          const checkIfAllNodesAreChecked = leafChildren.map((node) =>
            node.isSelected()
          );
          // console.log(node);

          // if (checkIfAllNodesAreChecked.length > 0) return;

          // if (
          //   !checkIfAllNodesAreChecked.every(
          //     (v) => v === checkIfAllNodesAreChecked[0]
          //   )
          // ) {
          //   // console.log(parentNode);
          //   return;
          // }

          if (checkIfAllNodesAreChecked.every(Boolean)) {
            node.setSelected(true);
            return;
          }
        });
      }

      params.node.childrenAfterGroup?.forEach((childNode) => {
        childNode.setSelected(true);
      });

      // console.log(selectedNodeCount);

      // If all nodes on the same level is selected then select parent node

      // if (selectedNodeCount?.every((v) => v === selectedNodeCount[0])) {
      //   params.node.parent?.setSelected(false);
      // }

      // // All nodes under parent are checked
      // if (selectedNodeCount?.every(Boolean)) {
      //   params.node.parent?.setSelected(true);
      // }

      // // All nodes under parent are not checked
      // if (selectedNodeCount?.every((v) => v === false)) {
      //   params.node.parent?.setSelected(false);
      // }

      // if (selectedNodeCount?.every(Boolean)) {
      //   params.node.parent?.setSelected(true);
      // }
    },
    // groupMultiAutoColumn: true,
    onGridReady: (params) => {
      gridApi.value = params.api;
      colApi.value = params.columnApi;
    },
    onRowDragMove: (event) => {
      setPotentialParentForNode(event.api, event.overNode);
    },
    onRowDragLeave: (event: RowDragLeaveEvent) => {
      setPotentialParentForNode(event.api, null);
    },
    onRowDragEnd: (event: RowDragEndEvent) => {
      const selectedNodes = getSelectedNodes.value!;
      const movingNode = event.node;
      const overNode = event.overNode;

      // Row Dragging
      if (!enableGroupMode.value) {
        if (selectedNodes.length > 0) {
          // the data we want to move
          let movingData = [] as any[];

          // If for some reason the selectedNodeArr list doesn't contain the initial selection add
          if (!selectedNodes.includes(movingNode)) {
            selectedNodes.unshift(movingNode);
          }
          movingData = selectedNodes;
          movingData.forEach((node) => {
            const rowsNeedsToMove = node !== overNode;
            if (rowsNeedsToMove && !enableGroupMode.value) {
              const movingData = node.data;
              const overData = overNode!.data;
              const fromIndex = aggridDataCopy.value.findIndex(
                (data) => data["doc_no"] === movingData["doc_no"]
              );
              console.log("fromIndex", fromIndex);
              const toIndex = aggridDataCopy.value.findIndex(
                (data) => data["doc_no"] === overData["doc_no"]
              );
              console.log("toIndex", toIndex);
              const newStore = aggridDataCopy.value.slice();
              moveInArray(newStore, fromIndex, toIndex);
              aggridDataCopy.value = newStore;
              gridApi.value!.setRowData(newStore);
              gridApi.value!.clearFocusedCell();
            }
          });
          return;
        }

        const rowNeedsToMove = movingNode !== overNode;
        if (rowNeedsToMove && !enableGroupMode.value) {
          const movingData = movingNode.data;
          const overData = overNode!.data;
          const fromIndex = aggridDataCopy.value.indexOf(movingData);
          const toIndex = aggridDataCopy.value.indexOf(overData);
          const newStore = aggridDataCopy.value.slice();
          moveInArray(newStore, fromIndex, toIndex);
          aggridDataCopy.value = newStore;
          gridApi.value!.setRowData(newStore);
          gridApi.value!.clearFocusedCell();
        }

        return;
      }

      if (!overNode) {
        return;
      }

      // Multi Row Grouping
      if (selectedNodes.length > 0) {
        overNode["data"]["type"] = "parent";
        selectedNodes.forEach((node) => (node["data"]["type"] = "child"));

        const folderToDropInto =
          overNode.data.type === "parent" ? overNode : overNode.parent;

        const newParentPath = folderToDropInto!.data
          ? folderToDropInto!.data.name
          : [];

        selectedNodes.forEach((node) => {
          const needToChangeParent = !arePathsEqual(
            newParentPath,
            node.data.name
          );
          const invalidMode = isSelectionParentOfTarget(node, folderToDropInto);
          if (invalidMode) {
            console.log("invalid move");
          }
          if (needToChangeParent && !invalidMode) {
            const updatedRows = [];
            moveToPath(newParentPath, node, updatedRows);

            gridApi.value!.applyTransaction({
              update: updatedRows,
            });
            gridApi.value!.clearFocusedCell();
            gridApi.value!.redrawRows();
          }
        });
        console.log("selectedNodes not empty");
        gridApi.value!.deselectAll();
        // setPotentialParentForNode(event.api, null);

        return;
      }

      // Single Row Grouping
      console.log("movingsNodes empty");
      overNode["data"]["type"] = "parent";
      movingNode["data"]["type"] = "child";

      const folderToDropInto =
        overNode.data.type === "parent" ? overNode : overNode.parent;

      const movingData = event.node.data;

      const newParentPath = folderToDropInto!.data
        ? folderToDropInto!.data.name
        : [];

      const needToChangeParent = !arePathsEqual(newParentPath, movingData.name);
      const invalidMode = isSelectionParentOfTarget(
        event.node,
        folderToDropInto
      );
      if (invalidMode) {
        console.log("invalid move");
      }
      if (needToChangeParent && !invalidMode) {
        const updatedRows = [];
        moveToPath(newParentPath, event.node, updatedRows);

        gridApi.value!.applyTransaction({
          update: updatedRows,
          addIndex: 0,
        });
        gridApi.value!.clearFocusedCell();
        gridApi.value!.redrawRows();
      }
      setPotentialParentForNode(event.api, null);
    },
  });

  // Don't use this one
  const bodyOptionsBottom = ref<GridOptions>({
    rowSelection: "single",
    enableRangeSelection: true,
    enableFillHandle: true,
    fillHandleDirection: "y",
    debounceVerticalScrollbar: true,
    // groupMultiAutoColumn: true,

    onGridReady: (params) => {
      gridApi.value = params.api;
      colApi.value = params.columnApi;
    },
  });

  // Don't use this one
  const customColumnDefs = ref<ColDef[]>([
    {
      maxWidth: 50,
      minWidth: 50,
      colId: "dragCol_1",
      rowDrag: false,
      suppressMenu: true,
      pinned: "left",
      lockPinned: true,
      dndSource: true,
    },
    {
      maxWidth: 50,
      minWidth: 50,
      colId: "checkboxCol_1",
      suppressMenu: true,
      pinned: "left",
      lockPinned: true,
    },
    {
      headerName: translate("id"),
      field: "doc_no",
      editable: false,
      flex: 1,
    },
    {
      headerName: translate("active"),
      field: "status",
      enableValue: true,
      cellEditorFramework: AgGridSwitch,
      valueFormatter: (params: ValueFormatterParams) => {
        // put the value in bold
        const isActive =
          params.value === true || params.value === "true" || params.value > 0;
        const isInActive =
          params.value === false ||
          params.value === "false" ||
          params.value <= 0;

        if (!isActive && !isInActive) {
          return params.value;
        }

        return `${isActive ? translate("active") : translate("inactive")}`;
      },
      // cellRendererFramework: AgGridSwitch,
    },
    {
      headerName: translate("projectCode"),
      field: "project_code",
      editable: false,
      flex: 1,
    },
    {
      headerName: translate("projectName"),
      field: "name",
    },
    {
      headerName: translate("company").trim(),
      field: "companyName",
    },
    {
      headerName: translate("projectLeader"),
      field: "project_manager",
    },
    {
      headerName: translate("fieldManager"),
      field: "department_manager",
    },
  ]);

  const customBottomTableColumnDefs = ref<ColDef[]>([
    // {
    //   maxWidth: 50,
    //   minWidth: 50,
    //   rowDrag: true,
    //   suppressMenu: true,
    //   dndSource: false,
    //   pinned: "left",
    //   lockPinned: true,
    //   colId: "dragCol",
    //   rowDragText: (params, dragItemCount) => {
    //     return (
    //       (dragItemCount > 1
    //         ? dragItemCount + " items"
    //         : params.rowNode?.data.name + " is") + " being dragged..."
    //     );
    //   },
    // },
    // {
    //   maxWidth: 50,
    //   minWidth: 50,
    //   suppressMenu: true,
    //   pinned: "left",
    //   lockPinned: true,
    //   colId: "checkboxCol",
    // },
    {
      headerName: translate("id"),
      field: "doc_no",
      editable: true,
    },
    {
      headerName: translate("active"),
      field: "status",
      enableValue: true,
      editable: true,
      cellEditorFramework: AgGridSwitch,
      valueFormatter: (params: ValueFormatterParams) => {
        // put the value in bold
        const isActive =
          params.value === true || params.value === "true" || params.value > 0;
        const isInActive =
          params.value === false ||
          params.value === "false" ||
          params.value <= 0;

        if (!isActive && !isInActive) {
          return params.value;
        }

        return `${isActive ? translate("active") : translate("inactive")}`;
      },
      // cellRendererFramework: AgGridSwitch,
    },
    {
      headerName: "Revenue | Total",
      // aggFunc: "sum",
      field: "contract_budget",
      editable: true,
      cellClass: (params) => {
        // console.log(params);
        if (!params.node.allChildrenCount) return "";
        return "fw-bolder fst-italic";
      },
      tooltipValueGetter: (params) => {
        if (!params) return "";
        if (!params.node!.allChildrenCount) {
          return "";
        }
        if (params.node!.allChildrenCount) {
          return handleGroupTotalTooltip(
            "contract_budget",
            params.node?.allLeafChildren
          );
        }
        return "";
      },
      valueFormatter: (params: ValueFormatterParams) => {
        if (!params) return "0";
        if (!params.node!.allChildrenCount) {
          return formatNumbers(+params["data"]["contract_budget"]);
        }
        if (params.node!.allChildrenCount) {
          return `${formatNumbers(
            params["data"]["contract_budget"]
          )} | ${formatNumbers(
            handleGroupTotal("contract_budget", params.node?.allLeafChildren)
          )}`;
        }
        return formatNumbers(params["data"]["contract_budget"]);
      },
    },
    {
      headerName: translate("projectCode"),
      field: "project_code",
      editable: true,
    },
    {
      headerName: translate("projectName"),
      field: "name",
      hide: true,
      editable: true,
    },
    {
      headerName: translate("company").trim(),
      field: "companyName",
      editable: true,
    },
    {
      headerName: translate("projectLeader"),
      field: "project_manager",
      editable: true,
    },
    {
      headerName: translate("fieldManager"),
      field: "department_manager",
      editable: true,
    },
  ]);

  const calculateId = computed(() => {
    if (!aggridData.value) {
      return [];
    }

    if (aggridData.value.length === 0) {
      return [];
    }
    const id = aggridData.value.map((row) => row["id"]);
    const sum = id.reduce(function(a, b) {
      return a + b;
    }, 0);
    return `TOTAL: ${sum}`;
  });

  const totalRevenue = computed(() => {
    if (aggridData.value.length === 0) {
      return [];
    }
    const revenue = aggridData.value.map((row) => +row["contract_budget"]);
    const sum = revenue.reduce(function(a, b) {
      return a + b;
    }, 0);
    return sum.toFixed(2);
  });

  const totalExpense = computed(() => {
    if (aggridData.value.length === 0) {
      return [];
    }
    const expense = aggridData.value.map((row) => +row["expense"]);
    const sum = expense.reduce(function(a, b) {
      return a + b;
    }, 0);
    return sum;
  });

  const pinnedBottomRowData = ref([
    {
      [Project.id]: calculateId,
      [Project.projectType]: "##",
      [Project.projectActivation]: "##",
      [Project.projectCode]: "##",
      [Project.projectName]: "##",
      [Project.revenue]: totalRevenue,
      [Project.projectCompany]: "##",
      [Project.projectManager]: "##",
      [Project.projectForeman]: "##",
      [Project.departmentManager]: "##",
      [Project.contractualDeadline]: "##",
      [Project.projectStatus]: "##",
      [Project.createdAt]: "##",
      [Project.updatedAt]: "##",
    },
  ]);

  const rowValueChanged = (args) => {
    if (args.oldData !== args.newData) {
      store
        .dispatch(AggridActions.SET_UNDO_ACTION, {
          type: "success",
          title: "Updating....",
          buttonTitle: "Undo",
          allowUndo: true,
          duration: 5500,
          api: gridApi.value,
        })
        .then((val) => {
          if (val !== "undo") {
            console.log("resolve", val);
            const update = {
              ...args.params.data,
            };
            gridApi.value!.applyTransaction({
              update: [update],
            });
          }
        })
        .catch((err) => {
          console.log("reject", err);
        });
    }
  };

  const transactionFinished = useDebounceFn(() => {
    console.log("finished");
    store
      .dispatch(AggridActions.SET_UNDO_ACTION, {
        type: "success",
        title: "Updating....",
        buttonTitle: "Undo",
        allowUndo: false,
        duration: 5500,
        api: gridApi.value,
      })
      .then((val) => {
        if (val !== "undo") {
          console.log("resolve", val);
          gridApi.value!.applyTransaction({
            update: queue.value,
          });
          store.dispatch(ProjectActions.UPDATE_PROJECT, {
            data: queueModified.value,
          });
          queue.value = [];
        }
      })
      .catch((err) => {
        console.log("reject", err);
        queue.value = [];
      });
  }, 3000);

  const cellValueChanged = (args) => {
    if (args.oldValue !== args.newValue) {
      queue.value.push(args.data);
      gridApi.value!.applyTransactionAsync(
        {
          update: queue.value,
        },
        transactionFinished
      );
    }
  };

  const deleteItem = ({ gridApi, selectedRow }) => {
    store
      .dispatch(AggridActions.SET_UNDO_ACTION, {
        type: "success",
        title: "Deleting....",
        buttonTitle: "Cancel",
        allowUndo: true,
        duration: 5500,
        api: gridApi,
      })
      .then((val) => {
        if (val !== "undo") {
          const update = {
            ...selectedRow,
            [Project.projectActivation]: false,
          };
          gridApi!.applyTransaction({
            update: [update],
          });
          gridApi!.refreshCells();
          store
            .dispatch(AggridActions.SET_UNDO_ACTION, {
              type: "success",
              title: "Delete Successful",
              buttonTitle: "Undo",
              allowUndo: true,
              duration: 5500,
              api: gridApi,
            })
            .then((val) => {
              if (val === "undo") {
                const update = {
                  ...selectedRow,
                  [Project.projectActivation]: 1,
                };
                gridApi!.applyTransaction({
                  update: [update],
                });
                gridApi!.refreshCells();
              } else {
                // Call Update method here
                store.dispatch(ProjectActions.UPDATE_PROJECT, selectedRow);
                gridApi!.refreshCells();
              }
            });
        }
      });
  };

  const applyState = useDebounceFn(() => {
    if (columnState.value.length === 0) return;
    const state = columnState.value[0]["state"];

    colApi.value!.applyColumnState({
      state: state,
      applyOrder: true,
    });
  }, 1000);

  const handleSaveState = useDebounceFn(() => {
    console.log("setState");
    if (aggridData.value.length === 0) return;
    const userId = store.getters.currentUser.id;
    const state = colApi.value!.getColumnState();

    const newState = {
      ...columnState.value[0],
      state: state,
      [State.tableName]: "projects",
      [State.userId]: userId,
    };
    console.log(newState);
    saveState(newState);
  }, 3000);

  const getNextId = () => {
    let rowId = gridApi.value?.getDisplayedRowCount();
    console.log(rowId);
    if (rowId === aggridDataCopy.value.length) {
      rowId++;
    }
    console.log(rowId);
    return rowId;
  };

  const addEmptyRowTree = () => {
    // console.log(props.gridApi);
    if (!gridApi.value) return;
    console.log("adding empty row");
    // getNextId();

    const docNo = Math.random()
      .toString(36)
      .substring(2, 20);

    const emptyRow = [
      {
        doc_no: docNo,
        status: 0,
        name: "Blank Row " + getNextId(),
        project_code: "",
        contract_budget: 0,
        tenantName: "",
        companyName: "",
        project_manager: "",
        project_foreman: "",
        department_manager: "",
        cost_end: "",
        project_progress: 0,
        project: "",
        object: "",
        wp: "",
      },
    ];

    aggridDataCopy.value.push(...emptyRow);

    console.log(emptyRow);
    gridApi.value?.applyTransaction({ add: emptyRow });
  };

  onMounted(() => {
    // Get all projects disregarding user id and sets init array
    // const allProjects =

    aggridData.value = PROJECTS;
    aggridDataCopy.value = PROJECTS;

    // applyState();
  });

  onUpdated(() => {
    // applyState();
  });

  return {
    groupNameArray,
    aggridData,
    addEmptyRowTree,
    aggridDataCopy,
    queue,
    frameworkComponents,
    customColumnDefs,
    customBottomTableColumnDefs,
    bodyOptionsTop,
    bodyOptionsBottom,
    rowValueChanged,
    cellValueChanged,
    pinnedBottomRowData,
    columnState,
    applyState,
    deleteItem,
    saveState,
    handleSaveState,
    enableGroupMode,
    groupRowsByCheckbox,
    unGroupRowsByCheckbox,
    moveRowsUpLevel,
    gridApi,
    colApi,
    getSelectedNodes,
    // autoGroupColumnDef,
  };
};
