enum Project {
  id = "doc_no",
  projectType = "project_type",
  revenue = "contract_budget",
  expense = "expense",
  netIncome = "netIncome",
  projectActivation = "project_activation",
  projectCode = "project_code",
  projectName = "project_name",
  projectCompany = "companyName",
  projectManager = "project_manager",
  projectForeman = "project_foreman",
  departmentManager = "department_manager",
  contractualDeadline = "contractual_deadline",
  projectStatus = "status",
  createdAt = "created_at",
  updatedAt = "updated_at",
}

export { Project };
