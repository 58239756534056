<template>
  <!--begin::Menu 3-->
  <div
    class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-bold w-200px py-3"
    data-kt-menu="true"
  >
    <!--begin::Heading-->
    <slot name="header"></slot>

    <!--end::Heading-->

    <slot name="menu-item"></slot>
  </div>
  <!--end::Menu 3-->
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "dropdown-3",
  components: {}
});
</script>
