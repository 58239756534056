
import { defineComponent } from "vue";
import AgGridFP from "@/components/widgets/tables/aggridfullpage/AgGridFullPage.vue";
import { ExampleSetup } from "./ExampleSetup";
import DropdownSlotVue from "@/components/dropdown/DropdownSlot.vue";

export default defineComponent({
  components: {
    AgGridFPTop: AgGridFP,
    DropdownSlotVue
  },
  setup(props) {

    const test = () => {
      console.log('hi')
    }

    const {
      groupNameArray,
      aggridData,
      aggridDataCopy,
      customColumnDefs,
      customBottomTableColumnDefs,
      frameworkComponents,
      bodyOptionsTop,
      bodyOptionsBottom,
      rowValueChanged,
      cellValueChanged,
      queue,
      pinnedBottomRowData,
      columnState,
      handleSaveState,
      deleteItem,
      enableGroupMode,
      groupRowsByCheckbox,
      unGroupRowsByCheckbox,
      moveRowsUpLevel,
      getSelectedNodes,
      gridApi,
      colApi,
      addEmptyRowTree
    } = ExampleSetup();

    return {
      groupNameArray,
      test,
      getSelectedNodes,
      groupRowsByCheckbox,
      unGroupRowsByCheckbox,
      moveRowsUpLevel,
      enableGroupMode,
      aggridData,
      aggridDataCopy,
      frameworkComponents,
      customColumnDefs,
      customBottomTableColumnDefs,
      bodyOptionsTop,
      bodyOptionsBottom,
      rowValueChanged,
      cellValueChanged,
      queue,
      pinnedBottomRowData,
      columnState,
      handleSaveState,
      deleteItem,
      gridApi,
      colApi,
      addEmptyRowTree
    };
  },
});
