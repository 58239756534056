
import DatePicker from "vue3-datepicker";
import { defineComponent, getCurrentInstance, onBeforeMount, ref, reactive, onMounted } from "vue";

export default defineComponent({
  props: ['params'],
  setup(props) {
    const cellParams = ref(props.params.value);
    const deadlineDate = ref(props.params.node.data["contractual_deadline"]);
    const formattedDateValue = new Date(deadlineDate.value.replace(/-/g, "/"));

    const getValue = () => {
      const year = formattedDateValue.getUTCFullYear();
      const month =
        formattedDateValue.getUTCMonth() + 1 < 10
          ? `0${formattedDateValue.getUTCMonth() + 1}`
          : formattedDateValue.getUTCMonth() + 1;
      const day =
        formattedDateValue.getUTCDate() < 10
          ? `0${formattedDateValue.getUTCDate()}`
          : formattedDateValue.getUTCDate();
      return `${year}-${month}-${day}`;
    };

    const getYesterdayDate = () => {
      const date = new Date();
      date.setDate(date.getDate() - 1);
      return date;
    }

    const weekAgo = () => {
      const date = new Date();
      date.setDate(date.getDate() - 7);
      return date;
    }

    const isCancelBeforeStart = () => {
      return false;
    };
    return {
      DatePicker,
      defineComponent,
      getCurrentInstance,
      onBeforeMount,
      ref,
      reactive,
      props,
      cellParams,
      deadlineDate,
      formattedDateValue,
      getValue,
      getYesterdayDate,
      weekAgo,
      isCancelBeforeStart,
    };
  },
});
