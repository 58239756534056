<template>
  <div>
    <!-- <AgGridFPTop
      id="table1"
      widget-classes="card-xxl-stretch mb-xl-3"
      @rowValueChanged="rowValueChanged"
      @gridApiReady="setGridApi"
      @colApiReady="setColApi"
      @deleteItemEmit="deleteItem"
      @displayedColumnsChanged="handleSaveState"
      :aggrid-data="aggridData"
      :custom-column-defs="customColumnDefs"
      :custom-body-options="bodyOptionsTop"
      :framework-components="frameworkComponents"
      :footerRowData="pinnedBottomRowData"
      :enable-row-group="true"
      :enableStatusBar="true"
    />-->
    <AgGridFPTop
      id="table1"
      widget-classes="card-xxl-stretch mb-xl-3"
      :aggrid-data="aggridDataCopy"
      :custom-column-defs="customBottomTableColumnDefs"
      :custom-body-options="bodyOptionsTop"
      :framework-components="frameworkComponents"
      :footerRowData="pinnedBottomRowData"
      :enable-row-group="true"
      :enable-status-bar="true"
      :enable-card-header="true"
      :enable-empty-row="true"
      :grid-api="gridApi"
      :col-api="colApi"
    >
      <template v-slot:toolbar-content>
        <div class="d-flex align-items-end w-100">
          <div class="switch_wrapper px-5 border-1 border-end">
            <!--begin::Label-->
            <label class="form-label fw-bold">Group Mode:</label>
            <!--end::Label-->

            <!--begin::Switch-->
            <div class="form-check form-switch form-check-custom form-check-solid">
              <input
                class="form-check-input"
                type="checkbox"
                name="Enable Group Mode"
                v-model="enableGroupMode"
              />
              <label class="form-check-label">Enabled</label>
            </div>
            <!--end::Switch-->
          </div>

          <div class="ms-5">
            <button class="btn btn-sm btn-primary" @click="addEmptyRowTree">
              <i class="fas fa-plus-square fs-7"></i>
              Add Empty Row
            </button>
          </div>

          <div class="ungroup_button ms-5">
            <!--begin::Menu-->
            <button
              type="button"
              class="btn btn-primary btn-sm"
              data-kt-menu-trigger="{default:'click', 'lg': 'hover'}"
              data-kt-menu-placement="bottom-end"
              data-kt-menu-flip="top-end"
            >
              <!-- 
              :disabled="getSelectedNodes.length === 0"

              -->
              <i class="fas fa-outdent fs-7"></i>
              Ungroup
              <span class="svg-icon svg-icon-2 me-0">
                <inline-svg src="media/icons/duotone/Navigation/Angle-down.svg" />
              </span>
            </button>
            <DropdownSlotVue>
              <template v-slot:menu-item>
                <div class="menu-item px-3">
                  <a
                    href="#"
                    class="menu-link px-3"
                    @click="moveRowsUpLevel"
                    :class="getSelectedNodes.length === 0 ? 'disabled' : ''"
                    :title="getSelectedNodes.length === 0 ? 'Requires at least 1 row selected' : ''"
                    :style="getSelectedNodes.length === 0 ? 'cursor: not-allowed' : ''"
                  >
                    Move Rows Up
                    <i
                      class="fas fa-exclamation-circle ms-2 fs-7"
                      data-bs-toggle="tooltip"
                      title="Move rows up within group"
                    ></i>
                  </a>
                </div>

                <div class="menu-item px-3">
                  <a
                    href="#"
                    class="menu-link px-3"
                    @click="unGroupRowsByCheckbox"
                    :class="getSelectedNodes.length === 0 ? 'disabled' : ''"
                    :title="getSelectedNodes.length === 0 ? 'Requires at least 1 row selected' : ''"
                    :style="getSelectedNodes.length === 0 ? 'cursor: not-allowed' : ''"
                  >Ungroup Rows</a>
                </div>
              </template>
            </DropdownSlotVue>
            <!--end::Menu-->
            <!-- <button class="btn btn-primary btn-sm">
              <i class="fas fa-outdent fs-7"></i>
              Ungroup
            </button>-->
          </div>
          <div class="group_button ms-5">
            <button
              class="btn btn-primary btn-sm"
              @click="groupRowsByCheckbox"
              :disabled="getSelectedNodes.length === 0 ? true : false"
              :title="getSelectedNodes.length === 0 ? 'Requires at least 2 rows selected' : ''"
              :style="getSelectedNodes.length === 0 ? 'cursor: not-allowed' : ''"
            >
              <i class="fas fa-indent fs-7"></i>
              Group
            </button>
          </div>
        </div>
      </template>
    </AgGridFPTop>

    <!-- <AgGridFPBottom
      id="table2"
      widget-classes="card-xxl-stretch mb-xl-3"
      :aggrid-data="aggridDataCopy"
      :custom-column-defs="customBottomTableColumnDefs"
      :custom-body-options="bodyOptionsBottom"
      :framework-components="frameworkComponents"
      :footerRowData="pinnedBottomRowData"
      :enable-row-group="false"
      :enable-status-bar="false"
      :enable-card-header="false"
    ></AgGridFPBottom>-->
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import AgGridFP from "@/components/widgets/tables/aggridfullpage/AgGridFullPage.vue";
import { ExampleSetup } from "./ExampleSetup";
import DropdownSlotVue from "@/components/dropdown/DropdownSlot.vue";

export default defineComponent({
  components: {
    AgGridFPTop: AgGridFP,
    DropdownSlotVue
  },
  setup(props) {

    const test = () => {
      console.log('hi')
    }

    const {
      groupNameArray,
      aggridData,
      aggridDataCopy,
      customColumnDefs,
      customBottomTableColumnDefs,
      frameworkComponents,
      bodyOptionsTop,
      bodyOptionsBottom,
      rowValueChanged,
      cellValueChanged,
      queue,
      pinnedBottomRowData,
      columnState,
      handleSaveState,
      deleteItem,
      enableGroupMode,
      groupRowsByCheckbox,
      unGroupRowsByCheckbox,
      moveRowsUpLevel,
      getSelectedNodes,
      gridApi,
      colApi,
      addEmptyRowTree
    } = ExampleSetup();

    return {
      groupNameArray,
      test,
      getSelectedNodes,
      groupRowsByCheckbox,
      unGroupRowsByCheckbox,
      moveRowsUpLevel,
      enableGroupMode,
      aggridData,
      aggridDataCopy,
      frameworkComponents,
      customColumnDefs,
      customBottomTableColumnDefs,
      bodyOptionsTop,
      bodyOptionsBottom,
      rowValueChanged,
      cellValueChanged,
      queue,
      pinnedBottomRowData,
      columnState,
      handleSaveState,
      deleteItem,
      gridApi,
      colApi,
      addEmptyRowTree
    };
  },
});
</script>

<style>
.hover-over {
  background-color: red;
}
</style>